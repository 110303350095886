// Migrated
<template>
  <div class="flex flex-wrap gap-3">
    <div
      v-for="{value, label, info} in durations"
      :key="value"
      class="flex flex-col justify-end text-center"
    >
      <div
        v-if="info"
        class="duration-info"
      >
        {{ info }}
      </div>
      <button
        :id="'duration-' + (value || 'flex') + '-button'"
        class="btn flex items-center justify-center duration-btn"
        :class="[ selectedDuration === value ? '!bg-vivid-turquoise hover:bg-light-turquoise' : '!bg-light-turquoise unchecked-btn' ]"
        type="button"
        @click="handleDuration(value)"
      >
        <fa
          v-if="selectedDuration === value"
          class="mr-2"
          :icon="icons.faCheck"
        />
        <span class="text-nowrap">
          {{ label }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

export default defineNuxtComponent({
  name: 'DurationSelectButtons',
  props: {
    selectedDuration: {
      type: [Number, Object],
      default: null,
    },
  },

  emits: ['select'],

  data () {
    return {
      icons: {
        faCheck,
      },

      durations: [
        {
          value: 7,
          label: this.$t('charter.duration.week', 1),
        },
        {
          value: 14,
          label: this.$t('charter.duration.week', 2),
        },
        {
          value: 21,
          label: this.$t('charter.duration.week', 3),
        },
        {
          value: 28,
          label: this.$t('charter.duration.week', 4),
        },
        {
          value: null,
          label: this.$t('charterSearchFlexibleDates'),
          info: this.$t('charterSearchSelectFlightHomeInfo'),
        },
      ],
    }
  },

  methods: {
    handleDuration (days) {
      this.$emit('select', days)
    },
  },
})
</script>

<style scoped lang="scss">
.duration-info {
  font-size: .7rem;
  color: theme('colors.darkest-gray');
}
.duration-btn {
  border-radius: 20px;
  min-width: 127px;
}
.unchecked-btn {
  background-color: theme('colors.lightest-gray');
  &:hover {
    background-color: theme('colors.light-gray');
  }
}
</style>
